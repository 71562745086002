:root {
  --header-height: 60px;
  --first-color: #000;
  --title-color: #fff;
  --font-semi-bold: 600;
  --h2-font-size: 1.5rem;
  --second-font: "Arial", sans-serif;
}
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 2;
}

.nav-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: var(--header-height);
  padding: 0 1rem;
}
.nav-menu{
    display:inherit;
}
.nav-logo {
    padding:3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  transition: color 0.4s;
  font-size: var(--h2-font-size);
  font-family: var(--second-font);
  text-decoration: none;
}

.logo-img{
    height:5rem;
    width:5rem;
    display:inline;
}
.nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
}

#nav_toggle,
#nav_close {
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
}

/* .nav-cta {
  background-color: #757575;
  color: #0000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.70rem 1.5rem;
  text-decoration: none;
} */

.nav-list {
  display: flex;
  flex-direction: row;
  list-style: none;
  column-gap: 2.5rem;
}

.nav-link {
  color: #fff;
  font-weight: var(--font-semi-bold);
  transition: color 0.4s;
  text-decoration: none;
}

.nav-link:hover {
  color: #ccc;
}

.nav-close {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

.show-menu {
  right: 0;
}

/* removing default button style */
.button {
  margin: 0;
  height: auto;
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
}
  
  /* button styling */
.button {
  --border-right: 6px;
  --text-stroke-color: rgba(255,255,255,0.6);
  --animation-color: #ffff;
  --fs-size: 1em;
  letter-spacing: 3px;
  text-decoration: none;
  font-size: var(--fs-size);
  font-family: var(--second-font);
  position: relative;
  color: transparent;
  -webkit-text-stroke: 1px var(--text-stroke-color);
}
/* this is the text, when you hover on button */
.hover-text {
  position: absolute;
  box-sizing: border-box;
  content: attr(data-text);
  color: var(--animation-color);
  width: 0%;
  inset: 0;
  border-right: var(--border-right) solid var(--animation-color);
  overflow: hidden;
  transition: 0.5s;
  -webkit-text-stroke: 1px var(--animation-color);
}
/* hover */
.button:hover .hover-text {
  width: 100%;
  filter: drop-shadow(0 0 23px var(--animation-color))
}
/* Login button */
.boton-elegante {
  display: flex;
  
  padding: 8px 22px;
  border: 2px solid #2c2c2c;
  background-color: #1a1a1a;
  color: #ffffff;
  font-size: 1.4rem;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.4s ease;
  outline: none;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}

.boton-elegante::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  transform: scale(0);
  transition: transform 0.5s ease;
}

.boton-elegante:hover::after {
  transform: scale(4);
}

.boton-elegante:hover {
  border-color: #666666;
  background: #292929;
}
