.container-booking{
    display:flex;
    justify-content: space-around;
    gap:10vw;
}

.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.booking-header {
  text-align: center;
  margin-bottom: 20px;
}

.booking-header h1 {
  font-size: 2rem;
}

.booking-header p {
  /* font-size: 1.2rem; */
  margin: 5px 0;
  padding:5px;
  border-radius: 5px;
}

.booking-form {
  width: 100%;
  background: #fff;
  min-width: 400px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
  color: #333;
}

.form-group input {
  width: 95%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.booking-button {
  width: 100%;
  padding: 10px;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.booking-button:hover {
  background: #555;
}
