.service-status-card {
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom:10px;
}

.service-status-card-image img {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 16px;
}

.service-status-card-info {
  display: flex;
  width: 100% !important;
  flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.service-status-card-section {
  margin-bottom: 8px;
}
.service-status-card-section {
    color:rgb(217, 204, 204);
}
.service-status-card-cancel-button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
}

.service-status-card-cancel-button:hover {
  background-color: #ff1a1a;
}
